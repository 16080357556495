import { useStaticQuery, graphql } from 'gatsby';

export const UseBlogList = () => {
	const blogs = useStaticQuery(graphql`
		query BlockGridList {
			blogs: allGroupContentLodgeGroupNodeBlog(
				filter: { relationships: { entity_id: { status: { eq: true } } } }
				sort: { order: DESC, fields: relationships___entity_id___created }
			) {
				edges {
					node {
						...nodeBlogFragment
					}
				}
			}
		}
	`);
	return blogs;
};
