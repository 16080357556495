import React from 'react';

const TextFilter = (props) => {
	return (
		<div className='bg-white'>
			<form>
				<div className='grid grid-cols-1 md:grid-cols-2'>
					<div className='md:justify-self-start py-2 md:py-0'>
						<div>
							<div className='rounded-md shadow-md'>
								<input
									id='title'
									className='form-input px-4 h-12 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-gray-500 border-gray-400'
									placeholder={props.placeholder}
									size='35'
								/>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default TextFilter;
