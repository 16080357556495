import React, {useState} from 'react';
import BlogCard from './BlogCard';
import BlogCardFull from './BlogCardFull';
import { UseBlogList } from './useBlogList';

const BlogGridList = (props) => {
	const blogList = UseBlogList();

	// Configure Load More functionality
	const articlesPerLoad = 6;

	const [next, setNext] = useState(articlesPerLoad);

	const handleMoreArticles = () => {
		setNext(next + articlesPerLoad);
	};

	// Filter out data for other groups
	const blogListResults = blogList.blogs.edges.filter((item) => {
		return item.node.r.group.gid.toString() === process.env.GATSBY_GROUP_ID;
	});

	// Reduce the blog count to match the paragraph's settings
	const paragraphBlogs = blogListResults.slice(0, props.number);
	const firstBlog = paragraphBlogs.shift();

	return (
		<div className='max-w-7xl mx-auto'>
			{/* Paragraph Grid List */}
			{firstBlog && props.type === 'paragraph' && (
				<div className='bg-white py-12 px-4 lg:px-0 lg:py-24'>
					<div className='relative max-w-full mx-auto '>
						<div>
							<h2 className='font-title text-4xl leading-9  font-extrabold text-gray-900 sm:text-4xl sm:leading-10'>
								{props.title}
							</h2>
							<div className='font-body font-semibold pt-3 sm:pt-4 pb-12 text-gray-900'>
								{props.summary}
							</div>
						</div>
						<div className='border-t border-gray-200 pt-8'>
							<BlogCardFull
								data={firstBlog}
								key={firstBlog.node.r.entity_id.id}
							/>
							<div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center md:gap-5 pt-10'>
								{paragraphBlogs.map((blog) => {
									return (
										<BlogCard data={blog} key={blog.node.r.entity_id.id} />
									);
								})}
							</div>
						</div>
						{props.link && (
							<div className='mt-8 sm:flex sm:justify-center lg:justify-center'>
								<div className='rounded-md shadow'>
									<a
										href={props.link.url}
										alt='See all articles'
										className='py-3 px-5 w-full flex items-center justify-center border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-ap-purple hover:bg-ap-purple-dark focus:outline-none transition duration-150 ease-in-out'>
										See all articles
									</a>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{/* Page Grid List */}
			{blogListResults.length > 0 && props.type === 'page' && (
				<div className='bg-white pb-20 flex flex-row'>
					<div className='relative max-w-lg mx-auto lg:max-w-7xl'>
						<div>
							<h2 className='font-title text-left text-3xl pb-6 pt-12 leading-9 font-extrabold sm:text-4xl'>
								{props.title}
							</h2>
							<div className='mt-3 sm:mt-4 lg:items-center'>
								<p className='text-xl leading-7 text-gray-500'>
									{props.summary}
								</p>
							</div>
						</div>

						<div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center md:gap-6'>
							{blogListResults?.slice(0, next)?.map((blog) => {
								return (
									<BlogCard
										data={blog}
										key={blog.node.r.entity_id.id}
										classes='text-left'
									/>
								);
							})}
						</div>
						{next < blogListResults?.length && (
							<button
								type='button'
								className='mt-10 inline-flex items-center px-5 py-3  text-base leading-6 font-medium rounded-md text-white bg-ap-purple hover:bg-ap-purple-dark focus:outline-none transition duration-150 ease-in-out'
								onClick={handleMoreArticles}>
								Load More
							</button>
						)}
					</div>
					{props.link && (
						<div className='mt-8 sm:flex sm:justify-center lg:justify-end'>
							<div className='rounded-md shadow'>
								<a
									href={props.link.url}
									alt='View All Events'
									className='py-3 px-5 w-full flex items-center justify-center border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo transition duration-150 ease-in-out'>
									View All Articles
								</a>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default BlogGridList;
