import React from 'react';

import Layout from '../../components/layout/layout';
import Seo from '../../components/layout/seo';
import { graphql } from 'gatsby';
import Parser from 'html-react-parser';
import BlogGridList from '../../components/content_type/Blog/BlogGridList';
import TextFilter from '../../components/fields/Filter/TextFilter';

// @TODO: SO-59 Update the key so not to use an index

const BlogListPage = ({ data }) => {
	const node = data.nodeListings;
	return (
		<Layout>
			<Seo title='Articles' keywords={[`gatsby`, `application`, `react`]} />
			<div className='bg-white max-w-7xl text-center mx-6 lg:mx-0'>
				<div className='text-center py-12 lg:py-24'>
					<div className=''>
						<h2 className='font-title text-4xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10'>
							{node.title}
						</h2>
						{node.subtitle && (
							<p className='text-xl leading-7 text-gray-500'>{node.subtitle}</p>
						)}
					</div>
					<div className='font-body font-semibold py-6 mx-8 xl:mx-56 text-gray-900'>
						{node.summary && <div>{Parser(node.summary)}</div>}
					</div>
					{/* <TextFilter placeholder='Search all articles...' /> */}
					<BlogGridList data={data.blogList.edges} type='page' />
				</div>
			</div>
		</Layout>
	);
};

export default BlogListPage;

export const query = graphql`
	query blogs($gid: Int, $slug: Int) {
		nodeListings(drupal_internal__nid: { eq: $slug }) {
			...listingPageFragment
		}
		blogList: allGroupContentLodgeGroupNodeBlog(
			filter: {
				relationships: {
					gid: { drupal_internal__id: { eq: $gid } }
					entity_id: { status: { eq: true } }
				}
			}
		) {
			edges {
				node {
					...nodeBlogFragment
				}
			}
		}
		lodge: groupLodge(drupal_internal__id: { eq: $gid }) {
			...lodgeFragment
		}
	}
`;
