import React from 'react';
import DateSingle from '../../fields/DateSingle';
import Image from '../../fields/Image';

const BlogCard = (props) => {
	const data = props.data.node.r.entity_id;
	const media = data.r.media;

	return (
		<div className='flex flex-1 my-5 flex-col overflow-hidden text-left'>
			<div className='flex-grow'>
				<div>
					{media && (
						<div className='flex-shrink-0'>
							<Image
								className='h-60 w-full rounded-md object-cover'
								image={media.r.image}
								alt={data.title}
								sizes='100w'
							/>
						</div>
					)}
				</div>
				<div className='bg-white flex flex-col justify-between'>
					<div className='flex-1'>
						<div className='block'>
							<div className='text-sm leading-5 text-gray-900 xl:py-3'>
								<DateSingle
									thisDate={data.created}
									classes='font-body font-semibold text-xs text-ap-purple uppercase'
								/>
							</div>
							<div className='font-title flex mt-2 text-xl  text-gray-900'>
								{data.title}
							</div>
							<div className='font-body mt-3 text-base leading-6 text-black pb-4'>
								{data.summary}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='items-end font-body mt-3 '>
				<a
					href={data.path.alias}
					className='flex group items-center text-base leading-6 font-semibold text-gray-900'>
					Read more
					<div className='transition duration-500 ease-in-out pl-2 opacity-0 group-hover:opacity-100'>
						<svg
							width='22'
							height='12'
							viewBox='0 0 22 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M1 6L21 6'
								stroke='black'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M16 1L21 6L16 11'
								stroke='black'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</div>
				</a>
			</div>
		</div>
	);
};

export default BlogCard;
