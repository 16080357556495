import React from 'react';
import PropTypes from 'prop-types';

const DateSingle = (props) => {
	return (
		<div>
			{/* {props.thisDate && ( */}
			<div className={`flex ${props.classes} justify-center uppercase`}>
				{new Intl.DateTimeFormat('en-US', {
					// weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				}).format(new Date(props.thisDate))}
			</div>
			{/* )} */}
		</div>
	);
};

DateSingle.propTypes = {
	thisDate: PropTypes.string,
	classes: PropTypes.string,
};

export default DateSingle;
