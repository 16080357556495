import React, { Component } from 'react';
import Image from './../../fields/Image';
import DateSingle from '../../fields/DateSingle';

const BlogCardFull = (props) => {
	const data = props.data.node.r.entity_id;
	const media = data.r.media;
	return (
		<div className={props.classes}>
			<div className='flex flex-col lg:flex-row'>
				<div className='w-full lg:w-1/2 object-contain content-center lg:mr-8'>
					{media && (
						<div className='h-full'>
							<Image
								className='object-cover rounded-md'
								image={media.r.image}
								alt={data.title}
								sizes='100w'
							/>
						</div>
					)}
				</div>
				<div className='w-full lg:w-1/2 min-h-full max-h-full'>
					<div className='text-sm py-4 lg:pt-0 pb-4 leading-5 text-gray-500'>
						<DateSingle
							thisDate={data.created}
							classes='font-body font-semibold text-sm text-ap-purple uppercase'
						/>
					</div>
					<div>
						<div className='font-title text-4xl lg:text-6xl  pb-4 lg:pb-8 font-semibold text-gray-900'>
							{data.title}
						</div>
						<div className='font-body mt-3 text-lg leading-6 text-gray-900'>
							{data.summary}
						</div>
					</div>
					<div className='mt-8 sm:flex sm:justify-center md:justify-start'>
						<div className='rounded-md shadow'>
							<a
								href={data.path.alias}
								alt='Load more events'
								className='py-3 px-7 w-full flex items-center justify-center border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-ap-purple hover:bg-ap-purple-dark focus:outline-none transition duration-150 ease-in-out'>
								Read More
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogCardFull;
